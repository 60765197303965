
import axios from 'axios';

import {
  defineComponent,
  defineAsyncComponent,
  ref,
  reactive,
  computed,
  onMounted,
} from 'vue';

import type {
  ErrorsMap,
  User,
  Authorization,
} from '@/types';

import {
  USER_TYPE_SUPER_ADMIN,
  USER_TYPE_ADMIN,
  USER_TYPE_MANAGER,
  USER_TYPE_AFFILIATE,
  USER_TYPES,
  USER_STATUSES,
  PAYOUT_PERIOD_BIWEEKLY,
  PAYOUT_PERIODS,
  USER_DEFAULT_MAX_SITES,
} from '@/libs/consts';

import useUser from '@/composable/useUser';

import useEntries from '@/composable/useEntries';

import useClipboard from '@/composable/useClipboard';

import useStatisticsLink from '@/composable/useStatisticsLink';

export default defineComponent({
  components: {
    UserContactInformation: defineAsyncComponent(() => import('@/components/UserContactInformation.vue')),
    UiSettings: defineAsyncComponent(() => import('@/components/UiSettings.vue')),
    PublisherPayoutInformation: defineAsyncComponent(() => import('@/components/PublisherPayoutInformation.vue')),
    Endpoints: defineAsyncComponent(() => import('@/views/endpoints/Endpoints.vue')),
    Payouts: defineAsyncComponent(() => import('@/views/payouts/Payouts.vue')),
    Campaigns: defineAsyncComponent(() => import('@/views/campaigns/Campaigns.vue')),
    Balance: defineAsyncComponent(() => import('@/components/Balance.vue')),
  },
  setup() {
    const { activeUser } = useUser();

    const { getEntryByRoute } = useEntries();

    const { copyToClipboard } = useClipboard();

    const { encodeStatisticsFilters } = useStatisticsLink();

    const userTypes = computed(() => USER_TYPES.filter((option) => option.value !== USER_TYPE_SUPER_ADMIN || activeUser.isSuperAdmin()));

    const {
      entriesLoading: projectsLoading,
      entries: projects,
      fetchEntries: fetchProjects,
    } = useEntries();

    const {
      entriesLoading: managersLoading,
      entries: managers,
      fetchEntries: fetchManagers,
    } = useEntries();

    const user = reactive<User>({
      auto_approve_campaigns: false,
      max_sites: USER_DEFAULT_MAX_SITES,
      show_bounces: true,
      firstname: null,
      lastname: null,
      telegram: null,
      skype: null,
      alias: null,
      payout_period: PAYOUT_PERIOD_BIWEEKLY,
      balance_overdraft: 0,
      comment: null,
      manager_id: null,
      ui_publisher_rtb: true,
      ui_publisher_direct: true,
      ui_advertiser: true,
      ui_referrer: true,
      ui_contact_notification: false,
      ui_contact_notification_via: null,
      payout_method: null,
      payout_method_crypto_wallet_address: null,
      payout_method_paypal_email: null,
      payout_method_payoneer_email: null,
      payout_method_webmoney_wallet: null,
      payout_method_wire: null,
      enable_pay_by_card: false,
    } as unknown as User);

    const userErrorsMap = ref<ErrorsMap>({});

    const fetchManagersWrapper = async (): Promise<void> => fetchManagers(
      '/api/user/getUsers',
      {
        project_id: activeUser.isSuperAdmin() ? user.project_id : undefined,
        type: [
          USER_TYPE_ADMIN,
          USER_TYPE_MANAGER,
        ],
      },
    );

    const authorizations = ref<Authorization[]>([]);

    const fetchAuthorizations = async (): Promise<void> => {
      const response = await axios.post(
        '/api/user/getAuthorizationsByUserId',
        {
          id: user.id,
        },
      );

      authorizations.value = response.data;
    };

    const fetchUser = async (): Promise<void> => {
      const entry = await getEntryByRoute('/api/user/getUserById');

      if (entry) {
        Object.assign(user, entry);

        Promise.all([
          fetchAuthorizations(),
        ]);
      }
    };

    const storeUser = async (): Promise<void> => {
      const response = await axios.post('/api/user/storeUser', user);

      Object.assign(user, response.data);
    };

    const onProjectChange = (): void => {
      user.manager_id = null;

      fetchManagersWrapper();
    };

    const generatePassword = (): void => {
      user.password = Math.random().toString(20).substr(2, 12);
    };

    const generateApiTokenLoading = ref<boolean>(false);

    const generateApiToken = async (): Promise<void> => {
      generateApiTokenLoading.value = true;

      const response = await axios.post('/api/user/generateApiToken', { id: user.id });

      user.api_token = response.data.api_token as string;

      generateApiTokenLoading.value = false;
    };

    const getLoginURL = async (): Promise<void> => {
      const response = await axios.post(
        '/api/user/getTokenByUserId',
        {
          user_id: user.id,
        },
      );

      const { token } = response.data;

      if (!user.project) {
        console.warn('user.project is undefined');

        return;
      }

      const url = `https://${user.project.host}/login?token=${token}`;

      await copyToClipboard(url);
    };

    onMounted((): void => {
      Promise.all([
        activeUser.isSuperAdmin() ? fetchProjects('/api/project/getProjects') : Promise.resolve(),
        fetchManagersWrapper(),
      ]);
    });

    return {
      USER_STATUSES,
      USER_TYPE_AFFILIATE,
      PAYOUT_PERIODS,
      activeUser,
      copyToClipboard,
      encodeStatisticsFilters,
      userTypes,
      projectsLoading,
      projects,
      managersLoading,
      managers,
      user,
      userErrorsMap,
      authorizations,
      fetchUser,
      storeUser,
      onProjectChange,
      generatePassword,
      generateApiTokenLoading,
      generateApiToken,
      getLoginURL,
    };
  },
});
